<script setup>
import { onMounted, onUnmounted, ref } from 'vue';

const emit = defineEmits(['files-dropped']);

const props = defineProps({
    disabled: {
        type: Boolean,
        default: false,
    },
});

const active = ref(false);
let inactiveTimeout = null;

const setActive = () => {
    if (props.disabled) {
        return;
    }

    active.value = true;
    clearTimeout(inactiveTimeout);
};

const setInactive = () => {
    inactiveTimeout = setTimeout(() => {
        active.value = false;
    }, 50);
};

const onDrop = (e) => {
    if (props.disabled) {
        return;
    }

    setInactive();
    emit('files-dropped', [...e.dataTransfer.files]);
};

const preventDefaults = (e) => {
    e.preventDefault();
};

const events = ['dragenter', 'dragover', 'dragleave', 'drop'];

onMounted(() => {
    events.forEach((eventName) => {
        document.body.addEventListener(eventName, preventDefaults);
    });
});

onUnmounted(() => {
    events.forEach((eventName) => {
        document.body.removeEventListener(eventName, preventDefaults);
    });
});
</script>

<template>
    <div
        :data-active="active"
        @dragenter.prevent="setActive"
        @dragover.prevent="setActive"
        @dragleave.prevent="setInactive"
        @drop.prevent="onDrop"
    >
        <slot
            :active="active"
            :disabled="disabled"
        />
    </div>
</template>
